<template>
  <div class="wrapper">
    <melon-header></melon-header>

    <el-carousel height="3.4rem">
      <el-carousel-item v-for="item in carouseList" :key="item.id">
        <img
          :src="item.path"
          class="carouselImg"
          alt="婚礼堂悦动,婚礼堂,banner"
          @click="toLink(item.link)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getCarouselList } from '@/api/index';
import store from '@/store/index';
import MelonHeader from '@/components/layout/MelonHeader.vue';

export default {
  components: {
    MelonHeader,
  },
  data() {
    return {
      carouseList: [
        // {
        //   id: '2',
        //   path: require('@/assets/image/bannerApp.jpg'),
        //   link: 'https://mp.weixin.qq.com/s/wl0CWceQUDluvGFpUTv9tA',
        // },
        {
          id: '4',
          path: require('@/assets/banner/banner3.jpg'),
          // link: 'https://mp.weixin.qq.com/s/OW4MafDjOO8awQRHR0HEQg',
        },
        // {
        //   id: '5',
        //   path: require('@/assets/banner/banner2.jpg'),
        //   // link: 'https://mp.weixin.qq.com/s/OW4MafDjOO8awQRHR0HEQg',
        // },
        // {
        //   id: '3',
        //   path: require('@/assets/image/bar_banner.png'),
        //   link: 'https://mp.weixin.qq.com/s/P9jWGdojuhTLvwM0gPFtTg',
        // },
      ],
      lrBoxVisible: false,
    };
  },
  created() {
    getCarouselList()
      .then((res) => {
        console.log('轮播图:', res);
        // this.carouseList = res.list.map((item) => ({
        //   path: item.bg_link,
        //   id: item.id,
        //   link: item.redirect || '',
        // }));
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    toLink(link) {
      if (link === 'yx') {
        if (store.state.token) {
          window.location.href = `${window.location.origin}/hm-customer/index.html#/app/yx`;
        } else {
          this.openLrBox(0);
        }
      } else if (link) {
        window.open(link, '_blank');
      }
    },
    openLrBox(type) {
      this.lrBoxVisible = true;
      this.$nextTick(() => {
        this.$refs.lrBoxDom.openBoxByType(type);
      });
    },
    handleCloseLrBox() {
      this.lrBoxVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .carouselImg {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
</style>
