<template>
  <div class="product-high">
    <div class="title">产品亮点</div>
    <div class="sub-title">
      <div>
        将所有产生过消费的用户，自动变成商家的会员，通过视频，红包，游戏，抽奖等各种方式，尝试激活，引导消费。
      </div>
      <div>
        并从后台管理系统中自动生成反馈，对客户的数据进行精准的分析，提供客户画像，方便商家精准销售。
      </div>
      <div>
        会员激活，销售过程，拼单，团购，分享，都可以持续提升品牌曝光和流量的引入。
      </div>
    </div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in topList" :key="index">
        <img :src="item.icon" alt="" class="icon" :class="'icon-'+(index +1)"/>
        <div class="card__title">{{ item.title }}</div>
        <div class="card__desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const topList = [
  {
    icon: require('@/assets/hlt/product-high/high1.png'),
    title: '嗨喵悦动 ',
    desc: '·现场互动神器/吸粉',
  },
  {
    icon: require('@/assets/hlt/product-high/high2.png'),

    title: '客户管理系统 ',
    desc: '·互动用户数据/转化',
  },
  {
    icon: require('@/assets/hlt/product-high/high3.png'),

    title: '商家会员商城 ',
    desc: '·承载用户流量/滞留',
  },
];
export default {
  name: 'ProductHigh',
  data() {
    return {
      topList,
    };
  },
};
</script>

<style lang="less" scoped>
.product-high {
  height: 565px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    width: 100%;
    margin-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card {
      margin-right: 24px;
      width: 360px;
      height: 240px;
      background: #f8f9fc;
      &:last-child {
        margin-right: 0px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon {
        // background: red;
        &-1 {
          width: 89px;
          height: 67px;
        }
        &-2 {
          width: 89px;
          height: 89px;
        }
        &-3 {
          width: 79px;
          height: 79px;
        }
      }
      &__title {
        margin-top: 8px;
        color: #333333;
        font-size: 20px;
        font-weight: 500;
      }
      &__desc {
        margin-top: 8px;
        color: rgba(51,51,51,1);
        font-size: 16px;
      }
    }
  }
}
</style>
