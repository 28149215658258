<template>
  <div class="solve-three">
    <div class="title">商家会员商城</div>
    <div class="card">
      <!-- <img
        :src="require('@/assets/hlt/four-slove/slove1.png')"
        alt=""
        class="card-img"
      /> -->
      <div class="card-img-wrap">
        <img
          class="card-img"
          v-for="img in imgList"
          :src="img"
          alt=""
          :key="img"
        />
      </div>
      <div class="card-box">
        <div class="card-box__title">丰富多样的营销工具，适配多种营销场景</div>
        <div class="card-box__desc">
          <div>婚礼堂品牌营销的重要载体，承载多数私域流量</div>
          <div>
            优惠券、抽奖等功能，吸引用户留存、复购，帮助婚礼堂业绩长效增长
          </div>
        </div>
        <div class="card-box-group">
          <div class="card-box-group__btn quick">立即咨询</div>
          <div class="card-box-group__btn free">免费试用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolveThree',
  data() {
    return {
      imgList: [
        require('@/assets/hlt/four-slove/slove3-1.png'),
        require('@/assets/hlt/four-slove/slove3-2.png'),
        require('@/assets/hlt/four-slove/slove3-3.png'),
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.quick {
  background: #167bed;
  color: #ffffff;
}
.free {
  background: rgba(22, 123, 237, 0.6);
  color: rgba(22, 123, 237, 1);
}
.solve-three {
  height: 647px;
  background: #f3f6fa;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card {
    width: 100%;
    margin: 0px 0;
    margin-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    .card-img-wrap {
      width: 666px;
      height: 358px;
      position: relative;
      .card-img {
        width: 364px;
        height: 491px;
        position: absolute;
        &:nth-child(1){
          left: 0px;
        }
        &:nth-child(2){
          left: 223px;

        }
        &:nth-child(3){
          left: calc( 446px);

        }
      }
    }
    // .card-img {
    //   width: 592px;
    //   height: 358px;
    // }
    .card-box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 124px;

      &__title {
        color: #333333;
        font-size: 24px;
        font-weight: 500;
      }
      &__desc {
        margin-top: 24px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
      }
      &-group {
        margin-top: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__btn {
          width: 128px;
          height: 46px;

          color: #fff;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
