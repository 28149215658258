<template>
  <div class="solve-four">
    <div class="title">全方位数据分析</div>

    <div class="card">
      <img
        :src="require('@/assets/hlt/four-slove/slove4.png')"
        alt=""
        class="card-img"
      />
      <div class="card-box">
        <div class="card-box__title">赋能商家定向决策，寻找精准客户</div>
        <div class="card-box__desc">
          <div>多维度的用户数据分析</div>
          <div>寻求客户的最大价值，提升婚礼堂运营业绩</div>
        </div>
        <div class="card-box-group">
          <div class="card-box-group__btn quick">立即咨询</div>
          <div class="card-box-group__btn free">免费试用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolveFour',
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.quick {
  background: #167bed;
  color: #ffffff;
}
.free {
  background: rgba(22, 123, 237, 0.6);
  color: rgba(22, 123, 237, 1);
}
.solve-four {
  height: 647px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card {
    width: 100%;
    margin: 0px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    .card-img {
      width: 666px;
      height: 458px;
    }
    .card-box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-right: 124px;

      &__title {
        color: #333333;
        font-size: 24px;
        font-weight: 500;
      }
      &__desc {
        margin-top: 24px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
      }
      &-group {
        margin-top: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__btn {
          width: 128px;
          height: 46px;

          color: #fff;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
