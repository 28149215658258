<template>
  <div class="quick-intro">
    <div class="title">传统婚礼堂亟需提升数字化能力</div>
    <div class="sub-title">
      疫情时代，线下实体经营尤为困难，婚礼堂提升数字化能力刻不容缓
    </div>
    <div class="card-wrap">
      <div class="diffcult-box">
        <div class="diffcult-box-title">经营困难</div>
        <div class="diffcult-box-card" v-for="box in boxList" :key="box.id">
          <div class="diffcult-box-card__title">{{ box.title }}</div>
          <div class="diffcult-box-card__desc">{{ box.d1 }}</div>
          <div class="diffcult-box-card__desc">{{ box.d2 }}</div>
        </div>
      </div>
      <div class="info-box">
        <div class="info-box__title">全方位数据化</div>

        <div class="info-box__circle">
          <div>全方位一体化</div>
          <div>嗨喵婚礼堂获客</div>
          <div>系统</div>
          <div class="a i-box">
            <div class="i-box_title">嗨喵大屏互动</div>
            <div class="i-box_body">现场互动、活跃气氛、 线下获取流量</div>
          </div>
          <div class="b i-box">
            <div class="i-box_title">客户管理系统</div>
            <div class="i-box_body">用户标签、智能触达、 统一客户管理</div>
          </div>
          <div class="c i-box">
            <div class="i-box_title">商家会员商城</div>
            <div class="i-box_body">品项售卖、营销推广、 深入挖掘用户价值</div>
          </div>
          <div class="d i-box">
            <div class="i-box_title">用户评价系统</div>
            <div class="i-box_body">用户反馈、品牌曝光、 增强与用户交流</div>
          </div>

          <div class="e u-box">
            <div class="u-box_title">经营提效</div>
            <div class="u-box_body">
              <div>线下获取精准客户</div>
              <div>线上架设品牌商城</div>
            </div>
          </div>
          <div class="f u-box">
            <div class="u-box_title">业绩增长</div>
            <div class="u-box_body">
              <div>打造以会员体验为中</div>
              <div>心的婚礼堂运营模式</div>
            </div>
          </div>
        </div>
      </div>
      <div class="benefit-box">
        <div class="benefit-box-card" v-for="box in benefitList" :key="box.id">
          <div class="benefit-box-card__title">{{ box.title }}</div>
          <div class="benefit-box-card__desc">{{ box.d1 }}</div>
          <div class="benefit-box-card__desc">{{ box.d2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const boxList = [
  {
    id: 'box1',
    title: '·经营方式单一',
    d1: '传统婚礼堂提供服务类型单一，难以抵御疫情带来的未知风险',
    d2: '婚礼现场互动方式单一，客户满意率较低',
  },
  {
    id: 'box2',

    title: '·客户获取困难',
    d1: '受疫情影响，线下门店客流量减少，服务性消费放缓',
    d2: '传统婚礼堂获客手段单一，获客难，成本高',
  },
  {
    id: 'box3',

    title: '·客户资产无沉淀',
    d1: '缺少客户运营工具和能力，难以发现精准客户',
    d2: '缺乏客户资产数据沉淀，营销策略不准确，客户转化少',
  },
  {
    id: 'box4',

    title: '·多门店运营成本高',
    d1: '多门店分散管理，效率低，准确率低',
    d2: '运营成本压力加大',
  },
];
const benefitList = [
  {
    id: 'benefit1',
    title: '拓展经营场景',
    d1: '聚合婚礼堂获取的流量',
    d2: '拓展多样化经营场景',
  },
  {
    id: 'benefit2',
    title: '构建私域流量池',
    d1: '全域用户沉淀',
    d2: '构建品牌私域流量池',
  },
  {
    id: 'benefit3',
    title: '系统集成精细管理',
    d1: '多业务系统打通',
    d2: '业务提效降低运营成本',
  },
  {
    id: 'benefit4',
    title: '客户精细化运营',
    d1: '多维度客户标签',
    d2: '精细化营销，定向运营',
  },
];
const topList = [
  {
    name: '专业的客户培训团队',
  },
  {
    name: '强大的技术保障',
  },
  {
    name: '7*24小时客服支持',
  },
];
export default {
  name: 'QuickIntro',
  data() {
    return {
      topList,
      boxList,
      benefitList,
    };
  },
};
</script>

<style lang="less" scoped>
.quick-intro {
  height: 1011px;
  background: #f3f6fa;
  padding-top: 60px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
    .diffcult-box {
      padding: 0 16px;
      width: 264px;
      height: 684px;
      background: #f3f6fa;
      border-radius: 4px;
      margin-right: 8px;
      &-title {
        width: 232px;
        height: 69px;
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-card {
        width: 232px;
        height: 138px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 12px;
        padding: 12px;
        &:nth-child(1) {
          // margin-top: 0px;
        }
        &__title {
          color: #333333;
          font-size: 16px;
        }
        &__desc {
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
        }
      }
    }
    .info-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &__title {
        width: 576px;
        height: 69px;
        background: #167bed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 24px;
      }
      &__circle {
        width: 318px;
        height: 318px;
        background-image: url("~@/assets/hlt/quick-intro/circle.png");
        background-size: 100% 100%;
        margin-top: 142px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #ffffff;
        font-size: 24px;
        position: relative;
        .i-box {
          position: absolute;
          width: 136px;
          height: 70px;
          background: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 4px 9px 0px rgba(86, 164, 255, 0.16);
          padding: 8px;
          @left: -60px;
          @top: -50px;
          &.a {
            left: @left;
            top: -30px;
          }
          &.b {
            right: @left;

            top: -30px;
          }
          &.c {
            left: @left;

            bottom: -44px;
          }
          &.d {
            right: @left;

            bottom: -44px;
          }
          &_title {
            color: #333333;
            font-size: 14px;
          }
          &_body {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
          }
        }
        .u-box {
          width: 160px;
          height: 160px;
          background: rgba(22, 123, 237, 0.1);
          border-radius: 50%;
          box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.8) inset;
          backdrop-filter: blur(9px);
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &.e{
            top: 90px;
            left: -100px;
          }
           &.f{
            top: 90px;
            right: -100px;
          }
          &_title{
            color: #333333;
            font-size: 16px;
          }
          &_body{
            margin-top: 12px;
            color: rgba(0,0,0,0.60);;
            font-size: 14px;
          }
        }
      }
    }
    .benefit-box {
      padding: 0 16px;
      width: 264px;
      height: 684px;
      background: #f3f6fa;
      border-radius: 4px;
      margin-right: 8px;
      &-title {
        width: 232px;
        height: 69px;
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-card {
        width: 232px;
        height: 138px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 28px;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:nth-child(1) {
          // margin-top: 0px;
        }
        &__title {
          color: #333333;
          font-size: 16px;
        }
        &__desc {
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
        }
        &:nth-child(1) {
          border-bottom: 4px solid #167bed;
        }
        &:nth-child(2) {
          border-bottom: 4px solid #f2c94c;
        }
        &:nth-child(3) {
          border-bottom: 4px solid #27ae60;
        }
        &:nth-child(4) {
          border-bottom: 4px solid #eb5757;
        }
      }
    }
  }
}
</style>
