<template>
  <div class="use-immediate">
    <div class="title">立即注册，免费试用30天，体验私域高效增长之旅！</div>
    <div class="btn" @click="showQrcode">联系客服</div>
  </div>
</template>

<script>
export default {
  name: 'UseImmediate',
  methods: {
    showQrcode() {
      alert('showQrcode');
    }
  }
};
</script>

<style lang="less" scoped>
.use-immediate {
  height: 336px;
  background: #157bec;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
  }
  .btn {
    width: 112px;
    margin-top: 50px;
    height: 42px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #167bed;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
