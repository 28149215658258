<template>
  <div class="maincontainer">
    <!-- <melon-header></melon-header> -->
    <banner></banner>
    <quick-intro></quick-intro>
    <product-high></product-high>
    <solve-one></solve-one>
    <solve-two></solve-two>
    <solve-three></solve-three>
    <solve-four></solve-four>
    <use-immediate></use-immediate>
    <about-us></about-us>
    <melon-footer></melon-footer>
    <toolBox @openQrcodeBox="openQrcodeBox" />

  </div>
</template>

<script>
import banner from '@/components/layout/banner.vue';
// import MelonHeader from '@/components/layout/MelonHeader.vue';
import MelonFooter from '@/components/layout/MelonFooter.vue';
import toolBox from '@/components/toolBox.vue';
import AboutUs from '../home/components/AboutUs.vue';
import UseImmediate from '../home/components/UseImmediate.vue';
import SolveFour from './components/SolveFour.vue';
import SolveTwo from './components/SolveTwo.vue';
import SolveOne from './components/SolveOne.vue';
import SolveThree from './components/SolveThree.vue';
import ProductHigh from './components/ProductHigh.vue';
import QuickIntro from './components/QuickIntro.vue';


export default {
  components: {
    banner,
    // MelonHeader,
    UseImmediate,
    AboutUs,
    MelonFooter,
    toolBox,
    SolveFour,
    SolveTwo,
    SolveOne,
    SolveThree,
    ProductHigh,
    QuickIntro,
  },
  methods: {
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
  },
};
</script>
